import type {
  RvUnifiedSearchParams,
  RvDetailsParams,
  ProfileOtherParams,
  UserReviewParams,
  RvDetailsOfOwnerParams,
  AccountRegisterParams,
  BookingTabHaveItemsParams,
  GetUserPorfilesListParams,
  ForgotPasswordRequest,
  RvReviewsPathParams,
  HostRvRatingsPathParams,
  RvReviewsQueryParams,
} from '~/types/rental-api-aliases'

/**
 * A collection of methods to call the RVezy API endpoints.
 * @returns An object containing various API methods for RVezy operations.
 */
export default function useRentalsApi() {
  /**
   * Fetches RVs based on the provided search parameters.
   * @param {Ref<RvUnifiedSearchParams>} params - The search parameters.
   * @returns {Promise<RvSearchResult>} A promise that resolves to the search results.
   * @example
   * const params = ref({ location: 'New York', startDate: '2023-07-01', endDate: '2023-07-07' });
   * const { data } = await getRVs(params);
   */
  function getRVs(params: Ref<RvUnifiedSearchParams>) {
    return useRentals('/api/rvlistings/unified-search', { params, transform: transformRVs })
  }

  /**
   * Fetches only featured RVs.
   * @param {Ref<RvUnifiedSearchParams>} params - The search parameters.
   * @returns {Promise<RvSearchResult>} A promise that resolves to the featured RV results.
   * @example
   * const params = ref({ limit: 5 });
   * const { data } = await getFeaturedRVs(params);
   */
  function getFeaturedRVs(params: Ref<RvUnifiedSearchParams>) {
    return useRentals('/api/rvlistings/unified-search', { params, transform: transformFeaturedRVs })
  }

  /**
   * Fetches only popular RVs.
   * @param {Ref<RvUnifiedSearchParams>} params - The search parameters.
   * @returns {Promise<RvSearchResult>} A promise that resolves to the popular RV results.
   * @example
   * const params = ref({ limit: 10 });
   * const { data } = await getPopularRVs(params);
   */
  function getPopularRVs(params: Ref<RvUnifiedSearchParams>) {
    return useRentals('/api/rvlistings/unified-search', { params, transform: transformPopularRVs })
  }

  /**
   * Fetches details for a specific RV.
   * @param {RvDetailsParams} params - The parameters for fetching RV details.
   * @returns {Promise<RvDetails>} A promise that resolves to the RV details.
   * @example
   * const { data } = await getRvlistingsDetails({ id: 123 });
   */
  function getRvlistingsDetails(params: RvDetailsParams) {
    return useRentals('/api/rvlistings/details', { params })
  }

  /**
   * Fetches the RV details of an owner.
   * @param {RvDetailsOfOwnerParams} path - The path parameters for fetching owner's RV details.
   * @returns {Promise<RvOwnerDetails>} A promise that resolves to the owner's RV details.
   * @example
   * const { data } = await getRvDetailsOfOwner({ profileId: 456 });
   */
  function getRvDetailsOfOwner(path: RvDetailsOfOwnerParams) {
    return useRentals('/api/rvlistings/rv-details-of-owner/{profileId}', { path })
  }

  /**
   * Fetches a user's profile.
   * @param {ProfileOtherParams} params - The parameters for fetching a user's profile.
   * @returns {Promise<UserProfile>} A promise that resolves to the user's profile.
   * @example
   * const { data } = await getUserProfilesOther({ userId: 789 });
   */
  function getUserProfilesOther(params: ProfileOtherParams) {
    return useRentals('/api/user-profiles/profile-other', { params })
  }

  /**
   * Gets all reviews for a user.
   * @param {UserReviewParams} path - The path parameters for fetching user reviews.
   * @returns {Promise<UserReviews>} A promise that resolves to the user's reviews.
   * @example
   * const { data } = await getReviewsByUserId({ userId: 789 });
   */
  function getReviewsByUserId(path: UserReviewParams) {
    return useRentals('/api/reviews/{userId}', { path })
  }

  /**
   * Fetches the list of user profiles.
   * @param {GetUserPorfilesListParams} query - The query parameters for fetching user profiles.
   * @returns {Promise<UserProfilesList>} A promise that resolves to the list of user profiles.
   * @example
   * const { data } = await getUserProfilesList({ page: 1, limit: 20 });
   */
  function getUserProfilesList(query: GetUserPorfilesListParams) {
    return useRentals('/api/user-profiles/get-list-profiles', { query })
  }

  /**
   * Registers a new account.
   * @param {AccountRegisterParams} body - The registration details.
   * @param {HeadersInit} headers - The headers for the request.
   * @returns {Promise<RegistrationResult>} A promise that resolves to the registration result.
   * @example
   * const { data } = await postAccountRegister({ email: 'user@example.com', password: 'password123' }, { 'Content-Type': 'application/json' });
   */
  function postAccountRegister(body: AccountRegisterParams, headers: HeadersInit) {
    return useRentals('/api/account/register', { method: 'POST', headers, body })
  }

  /**
   * Retrieves the current user's permissions.
   * @returns {Promise<UserPermissions>} A promise that resolves to the user's permissions.
   * @example
   * const { data } = await getUserPermissions();
   */
  function getUserPermissions() {
    return useRentals('/api/account/me/permissions')
  }

  /**
   * Initiates the forgot password process.
   * @param {ForgotPasswordRequest} body - The forgot password request details.
   * @returns {Promise<ForgotPasswordResult>} A promise that resolves to the forgot password result.
   * @example
   * const { data } = await forgotPassword({ email: 'user@example.com' });
   */
  function forgotPassword(body: ForgotPasswordRequest) {
    return useRentals('/api/forgot-password/request-code', {
      method: 'POST',
      body,
    })
  }

  /**
   * Checks if tabs have unread items.
   * @param {BookingTabHaveItemsParams} query - The query parameters for checking unread items.
   * @returns {Promise<BookingTabItems>} A promise that resolves to the booking tab items status.
   * @example
   * const { data } = await getBookingTabHaveItems({ userId: 123 });
   */
  function getBookingTabHaveItems(query: BookingTabHaveItemsParams) {
    return useRentals('/api/booking/tab-have-items', { query })
  }

  /**
   * Gets all reviews for a user.
   * @example
   * const { data } = await getReviewsByUserId({ userId: 789 });
   */
  function getRvReviews(path: RvReviewsPathParams | Ref<RvReviewsPathParams>, query: RvReviewsQueryParams | Ref<RvReviewsQueryParams>, options?: unknown) {
    return useRentals('/api/reviews/rvs/{rvId}', {
      path: path as RvReviewsPathParams,
      query,
      ...options as object,
    })
  }

  function getMyRvs() {
    return useRentals('/api/rvlistings/myrvs')
  }

  function getRvRatings(path: RvReviewsPathParams | Ref<HostRvRatingsPathParams>) {
    return useRentals('/api/hosts/ratings/{rvId}', {
      path: path as HostRvRatingsPathParams,
    })
  }

  function getHostMetrics() {
    return useRentals('/api/hosts/insights')
  }

  return {
    getRVs,
    getFeaturedRVs,
    getPopularRVs,
    getRvlistingsDetails,
    getRvDetailsOfOwner,
    getMyRvs,

    getUserProfilesOther,
    getReviewsByUserId,
    getUserProfilesList,

    postAccountRegister,
    getUserPermissions,
    forgotPassword,

    getBookingTabHaveItems,

    getRvReviews,
    getRvRatings,

    getHostMetrics,
  }
}
