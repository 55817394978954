import { createUseOpenFetch } from '#imports'
import type { paths as RentalsPaths, operations as RentalsOperations } from '#open-fetch-schemas/rentals'
import type { paths as GisPaths, operations as GisOperations } from '#open-fetch-schemas/gis'

export type OpenFetchClientName = 'rentals' | 'gis'

/**
 * Fetch data from an OpenAPI endpoint with an SSR-friendly composable.
 * See {@link https://nuxt-open-fetch.vercel.app/composables/useclient}
 * @param string The OpenAPI path to fetch
 * @param opts extends useFetch, $fetch options and useAsyncData options
 */
export const useRentals = createUseOpenFetch<RentalsPaths>('rentals')
/**
 * Fetch data from an OpenAPI endpoint with an SSR-friendly composable.
 * See {@link https://nuxt-open-fetch.vercel.app/composables/uselazyclient}
 * @param string The OpenAPI path to fetch
 * @param opts extends useFetch, $fetch options and useAsyncData options
 */
export const useLazyRentals = createUseOpenFetch<RentalsPaths>('rentals', true)

export type RentalsResponse<T extends keyof RentalsOperations, R extends keyof RentalsOperations[T]['responses'] = 200 extends keyof RentalsOperations[T]['responses'] ? 200 : never> = RentalsOperations[T]['responses'][R] extends { content: { 'application/json': infer U } }
  ? U
  : never

export type RentalsRequestBody<T extends keyof RentalsOperations> = RentalsOperations[T]['requestBody'] extends { content: { 'application/json': infer U } }
  ? U
  : never

export type RentalsRequestQuery<T extends keyof RentalsOperations> = RentalsOperations[T]['parameters'] extends { query?: infer U } ? U : never

/**
 * Fetch data from an OpenAPI endpoint with an SSR-friendly composable.
 * See {@link https://nuxt-open-fetch.vercel.app/composables/useclient}
 * @param string The OpenAPI path to fetch
 * @param opts extends useFetch, $fetch options and useAsyncData options
 */
export const useGis = createUseOpenFetch<GisPaths>('gis')
/**
 * Fetch data from an OpenAPI endpoint with an SSR-friendly composable.
 * See {@link https://nuxt-open-fetch.vercel.app/composables/uselazyclient}
 * @param string The OpenAPI path to fetch
 * @param opts extends useFetch, $fetch options and useAsyncData options
 */
export const useLazyGis = createUseOpenFetch<GisPaths>('gis', true)

export type GisResponse<T extends keyof GisOperations, R extends keyof GisOperations[T]['responses'] = 200 extends keyof GisOperations[T]['responses'] ? 200 : never> = GisOperations[T]['responses'][R] extends { content: { 'application/json': infer U } }
  ? U
  : never

export type GisRequestBody<T extends keyof GisOperations> = GisOperations[T]['requestBody'] extends { content: { 'application/json': infer U } }
  ? U
  : never

export type GisRequestQuery<T extends keyof GisOperations> = GisOperations[T]['parameters'] extends { query?: infer U } ? U : never
